<template>
  <div>
    <div class="email-settings">
      <div v-if="state === 'edit'">
        <div v-if="this.msg !== null" class="alert alert-success">{{ this.msg }}</div>
        <div v-if="error !== null" class="alert alert-danger">Error: {{ error.message }}</div>
        <div class="mb-3">
          <label>
            Name
            <sup>*</sup>
          </label>
          <input
            type="text"
            name="name"
            v-model="settings.name"
            required
            class="form-control form-control-lg"
          />
          <!--<span v-if="!settings.name" class="invalid-feedback is-invalid">Your name is required</span>-->
        </div>
        <div class="mb-3">
          <label>
            Email
            <sup>*</sup>
          </label>
          <input
            type="email"
            name="email"
            v-model="settings.email.value"
            required
            class="form-control form-control-lg"
          />
          <!--<span
            v-if="!settings.email.valid || !settings.email.value"
            class="invalid-feedback"
          >A valid email is required. This is important as we use this to send you important information about your customer payer status.</span>
          </div>-->
          <MembershipType v-if="this.$store.state.signup_type === 'advisor'" />
          <br />
          <p v-if="crisko_user_setup_incomplete">
            <label>
              <input
                type="checkbox"
                name="subscribe"
                v-model="settings.newsletter_optin"
                checked="true"
              /> Keep me posted as PayPredict launches new features to help my business.
            </label>
          </p>
        </div>
        <!-- <div class="spinner" v-else-if="state === 'submitting'">
          <i class="fas fa-2x fa-spinner fa-pulse"></i>
        </div>-->
        <button
          v-if="state === 'edit'"
          v-on:click="submit"
          class="btn btn-primary w-100"
          :disabled="(!settings.email.valid || !settings.email.value) || !settings.name"
          id="btnSaveAndContinue"
        >Save and Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MembershipType from "@/components/MembershipType.vue";
var emailRegExp = /^[^\][{}|#?/:<>%`\\\x00-\x1f\x7f ][^\][{}|#?/:<>%`\\\x00-\x1f\x7f]*@[^\]"'@[{}|#?/:<>%`\\\x00-\x1f\x7f ]+\.[^\]"'@[{}|#?/:<>%`\\\\\x00-\x1f\x7f ]{2,}$/;
export default {
  name: "email-settings",
  components: {
    MembershipType,
  },
  props: {
    submit_action: String,
  },
  data() {
    return {
      state: "edit",
      error: null,
      msg: null,
      settings: {
        name: this.$store.state.session_info.crisko_user_info.name,
        email: {
          value: this.$store.state.session_info.crisko_user_info.email,
          valid: emailRegExp.test(
            this.$store.state.session_info.crisko_user_info.email
          ),
        },
        membership_type: "",
        membership_id: "",
        role: this.$store.state.session_info.crisko_user_info.role,
        referral_client_id: this.$store.state.session_info.crisko_user_info
          .referral_client_id,
        newsletter_optin: true,
      },
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.settings.role = this.$store.state.session_info.crisko_user_info.role;
      //this.settings.referral_client_id = this.$store.state.session_info.crisko_user_info.referral_client_id;
      if (this.settings.role == "advisor") {
        // YES They are an accountant or Bookkeeper
        if (this.$store.state.session_info.crisko_user_info.membership_type) {
          this.settings.membership_type = this.$store.state.session_info.crisko_user_info.membership_type;
        } else if (localStorage.membership_type) {
          this.settings.membership_type = localStorage.membership_type;
        }
        if (this.$store.state.session_info.crisko_user_info.membership_id) {
          this.settings.membership_id = this.$store.state.session_info.crisko_user_info.membership_id;
        } else if (localStorage.membership_id) {
          this.settings.membership_id = localStorage.membership_id;
        }
      }
      // check if refer_id exists on localStorage.
      if (localStorage.refer_id) {
        this.settings.referral_client_id = localStorage.refer_id;
      } else if (
        this.$store.state.session_info.crisko_user_info.referral_client_id
      ) {
        this.settings.referral_client_id = this.$store.state.session_info.crisko_user_info.referral_client_id;
      }
    });
  },
  methods: {
    submit() {
      // These settings are common to both init_account and update_settings API methods
      var settings = {
        name: this.settings.name,
        email: this.settings.email.value,
        membership_type: localStorage.getItem("membership_type"),
        membership_id: localStorage.getItem("membership_id"),
        newsletter_optin: this.settings.newsletter_optin,
      };
      localStorage.user_name = this.settings.name;
      // Both email and name must be valid
      if (this.settings.email.valid && this.settings.name) {
        this.state = "submitting";

        // Could be init of account or update from settings page
        if (this.submit_action === "add") {
          // Init account

          // Set referral id
          settings.referral_client_id = this.settings.referral_client_id;

          // Submit
          this.$store.dispatch("init_account", settings).catch((error) => {
            this.state = "edit";
            this.error = error;
          });
        } else {
          // Settings update
          this.$store
            .dispatch("update_settings", settings)
            .then(() => {
              this.msg = "Your settings have been saved";
              this.state = "edit";
            })
            .catch((error) => {
              this.state = "edit";
              this.error = error;
            });
        }
      }
    },
    validate: function (type, value) {
      if (type === "email") {
        this.settings.email.valid = this.isEmail(value) ? true : false;
      }
    },
    // check for valid email adress
    isEmail: function (value) {
      return emailRegExp.test(value);
    },
  },
  computed: {
    crisko_user_setup_incomplete() {
      return this.$store.getters.crisko_user_setup_incomplete;
    },
  },
  watch: {
    // watching nested property
    "settings.email.value": function (value) {
      this.validate("email", value);
    },
  },
};
</script>

<style lang="scss">
label > span {
  color: red;
  font-size: 0.9rem;
}
</style>
