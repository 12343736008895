// Dependencies
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import axios from 'axios'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false
Vue.use(VueCookie);

// Our our styles and our overrides
import './assets/styles/main.scss'

//import './assets/styles/auth-trimmed.scss'

// Our application
import App from './App.vue'
import router_factory from './router'
import store from './store'
// import './registerServiceWorker'
import api from './api.js'
import moment from 'moment';


Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});

Vue.filter('formatDateShort', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM')
    }
});

Vue.filter('formatDateLong', function (value) {
    if (value) {
        return moment(String(value)).format('dddd, MMMM Do YYYY')
    }
});


window.api = api


// Only execute the app if the app element is there
if (document.getElementById('app') !== null) {


    // Router
    const router = router_factory()

    // // Google Tag Manager
    // Vue.use(
    //     VueGtag,
    //     {
    //         config: {
    //             // id: "UA-127667961-34",
    //             // id: "GTM-5L72W3M",
    //             params: {
    //                 send_page_view: false
    //             }
    //         }
    //     },
    //     router
    // );

    // Setup app
    window.app = new Vue({
        router: router,
        store,
        render: h => h(App)
    }).$mount('#app')

    // Configure axios to add csrf token
    axios.defaults.transformRequest.push(function (data, headers) {
        headers['X-CSRF-Token'] = window.app.$cookie.get('csrf_token');
        return data;
    })

    api.interceptors.request.use((config) => {
        const share_organisation = window.app.$store.state.share_organisation
        if (
            (config.url.startsWith("integrations/authed-xero") || config.url == "@@session-info") &&
            share_organisation.xero_authed_organisation_id
        ) {
            config.headers['Xero-Share-Org'] = share_organisation.xero_authed_organisation_id;
            config.headers['Xero-Share-Org-Token'] = share_organisation.share_secret;
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    });

}
Vue.config.devtools = true