<template>
  <div >
    <div v-if="xero_is_connected"  class="d-grid gap-2">
      <h2>Select an Organisation</h2>
      <div v-for="org in connected_orgs" :key="org.id" class="d-grid gap-2">
        <button class="btn btn-secondary" style="height: 50px;" @click="setSelectedOrg(org.id)">{{ org.name }}</button>
      </div>
      <button
        class="btn btn-primary"
        @click="goToXeroLogin()"
        id="btnConnectWithXeroIdle"
      >
        <img
          src="@/assets/img/xero-logo.png"
          style="width: 40px"
        />
        <span class="ml-3">
          Connect Another Organisation
        </span>
      </button>
    </div>
    <div v-else  class="d-grid gap-2">
      <h2>Connect a Xero Organisation</h2>
      <p>Sign in with Xero to connect an organisation to your account.</p>
      <button
        class="btn btn-primary"
        @click="goToXeroLogin()"
        id="btnConnectWithXeroIdle"
      >
        <img
          src="@/assets/img/xero-logo.png"
          style="width: 40px"
        />
        <span class="ml-3">
          Connect with Xero
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrgSelector",
  computed: {
    connected_orgs() {
      return this.$store.getters.connected_orgs
    },
    xero_is_connected() {
      return this.$store.getters.xero_is_connected
    },
  },
  methods: {
    goToXeroLogin() {
      location.assign("/login/xero");
    },
    setSelectedOrg(org_id) {
      const url = new URL(window.location.href)
      url.searchParams.set('orgId', org_id)
      // Note: setting window.location.href refreshes the page
      window.location.href = url.toString()
    },
  },
};
</script>
<style type="text/css" scoped>

</style>