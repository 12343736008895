<template>
  <div>
    <div v-on:exit="create_account=false"  class="d-grid gap-2">
      <h2>Connect your Xero account</h2>
      <p class="mb-4"> Connecting your Xero account lets us know which customers to include in your report</p>
      <button
        class="btn btn-primary w-100"
        @click="GoToLogin()"
        id="btnConnectWithXeroBusiness"
      >
        <img src="@/assets/img/xero-logo.png" style="width:40px" />
        
        Connect with Xero
        
      </button>
    </div>
  </div>
</template>

<script>
// import EmailSignUp from "@/components/EmailSignUp.vue";
// import EmailLogin from "@/components/EmailLogin.vue";

export default {
  name: "referlogin",
  computed: {
    // signup_type() {
    //   return this.$store.state.signup_type;
    // },
    // signup_referral_id() {
    //   return this.$store.state.signup_referral_id;
    // },
    // signup_title() {
    //   if (["advisor", "referred_by_advisor"].includes(this.signup_type)) {
    //     return "Sign up to PayPredict for free, forever! Thanks to your advisor.";
    //   }
    //   return "";
    // },
  },
  components: {
    // EmailSignUp,
    // EmailLogin,
  },
  data() {
    return {
      sign_up: false,
      login: false,
      create_account: false,
    };
  },
  methods: {
    GoToLogin() {
      location.assign("/login/xero?intent=minireport");
    },
    // GoogleLogin() {
    //   location.assign("/login/google");
    // },
    // switch_to_signup() {
    //   this.login = false;
    //   this.sign_up = true;
    // },
  },
};
</script>
<style type="text/css" scoped>
#circle-white-background {
  background: white;
  padding: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
</style>