<template>
  <footer class="footer">
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="col-md-4 col-sm-10">
                <img src="https://evenly.com.au/themes/evenly/assets/images/evenly-logo-white-sm.png" class="fluid logo">
                <hr>
                <p>
                    © Copyright 2020 Evenly
                    <br>
                    Built by 25/Fifteen</p>

                <img src="https://evenly.com.au/themes/evenly/assets/images/xero_logo.png" class="img-fluid">
            </div>
            <div class="col-md-4 col-sm-10">
                <p>
                    <a target="__blank" href="https://evenly.com.au/paypredict">What is PayPredict</a>
                    <br>
                    <a target="__blank" href="https://evenly.com.au/paypredict/help">Frequently Asked Questions</a>
                    <br>
                    <a target="__blank" href="https://evenly.com.au/paypredict/help#getstarted">Getting Started</a>
                    <br>
                    <a target="__blank" href="/paypredict/pricing">Pricing</a>
                </p>
            </div>
            <div class="col-md-4 col-sm-10">
                <p>
                   
                 
                    <a target="__blank" href="https://evenly.com.au/contact">Contact</a>
                    <br>
                    <a target="__blank" href="https://evenly.com.au/about">About</a>
                    <br>
                    <a target="__blank" href="https://evenly.com.au/blog">Blog</a>
                    <br>
                    <a target="__blank" href="https://evenly.com.au/terms">Terms and Conditions</a>
                    <br>
                    <a target="__blank" href="https://evenly.com.au/privacy">Privacy</a>
                   
                </p>
            </div>
        </div>
    </div>
  </footer>
</template>


<script>
export default {
  name: "Footer",
  
  data: function () {
    return {
      isLoading: false,
    };
  },
  methods: {
    async refresh_xero() {
      this.isLoading = true;
      await this.$store.dispatch("resync_and_reload_contacts_report");
      this.isLoading = false;
    },
    async reload_contacts_report() {
      await this.$store.dispatch("reload_contacts_report");
    },
  },
  computed: {
    finished_syncing() {
      return this.$store.getters.finished_syncing;
    },
    freshReportAvailable() {
      if (!this.$store.state.contacts_report.report_id) {
        return false;
      }
      if (!this.$store.state.authed_xero.current_report_id) {
        return false;
      }
      return (
        this.$store.state.authed_xero.current_report_id !=
        this.$store.state.contacts_report.report_id
      );
    },
  },
};
</script>

<style scoped>

footer {
      background: #495867;
    background: -moz-linear-gradient(45deg, #495867 0%, #495b69 100%);
    background: -webkit-linear-gradient(
    45deg
    , #495867 0%,#495b69 100%);
    background: linear-gradient(
    45deg
    , #495867 0%,#495b69 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#495867', endColorstr='#495b69',GradientType=1 );
    min-height: 50vh;
    padding-top: 100px;
    padding-bottom: 100px;
    color: #fff!important;
}
footer .logo {
  height: auto;
  width: 90px;
  display: block;
}

footer a {
  color: #fff!important;
  text-decoration: none;
}
</style>
