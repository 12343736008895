<template>
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <router-link to="/dashboard/" class="navbar-brand brand-logo mr-5">
        <img src="@/assets/images/paypredict-logo-white.svg" class="mr-2" alt="logo" />
      </router-link>
    </div>
       

    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <div class="btn-group">
        <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ti-user ml-2 mx-0"></i>
          {{ $store.state.authed_xero.organisation_name }}
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <ul class="list-group nav">
            <li class="nav-item">
              <router-link to="/dashboard/" class="nav-link">
                <i class="ti-bar-chart-alt menu-icon"></i>
                <span class="menu-title">Mini Report</span>
              </router-link>
            </li>
            
            <li class="nav-item">
              <a href="/logout/" class="nav-link">
                  <i class="ti-back-left menu-icon"></i>
                  <span class="menu-title">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Sharing Link Modal
    When I tried to put the modal code in a separate component it would not display, so that's why it's in this component.
    -->
    <div class="modal fade" data-backdrop="" id="exampleModal" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Sharing Link</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              This link gives read-only access to the dashboard, customer list,
              and overdue invoices. The person using the link cannot send emails
              or make any settings changes.
            </div>
            <br />
            <div id="shareLinkText">
              <input
                @click="$event.currentTarget.select()"
                type="text"
                :value="share_link"
                readonly
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>


  </nav>
</template>

<script>
import {mapGetters} from 'vuex'
import api from "@/api.js";
//import $ from "jquery";
export default {
  name: "Header",
  data: function() {
    return {
      isLoading: false,
      share_link: "Loading your link...",
      password_reset: "",
      login: {
        email: "",
        password: ""
      },
      reset: {
        email: ""
      }
    };
  },
  methods: {
    set_tab(value) {
      this.$emit("navigate", value);
    },
    clear_current_org() {
      this.$store.dispatch('deselect_current_organisation')
    },
    async refresh_xero() {
      this.isLoading = true;
      await this.$store.dispatch("resync_and_reload_contacts_report");
      this.isLoading = false;
    },
    async reload_contacts_report() {
      await this.$store.dispatch("reload_contacts_report");
    },
    activateSharingLink() {
      api
        .get(`integrations/authed-xero/` + this.current_xero_organisation + `/@@sharing-link`)
        .then(
          (resp) => (this.share_link = resp.data.link + window.location.hash)
        );
    },
  },
  props: {
    is_app: Boolean
  },
  computed: {
    ...mapGetters(["current_xero_organisation", "is_view_only_user"]),

    finished_syncing() {
      return this.$store.getters.finished_syncing;
    },
    freshReportAvailable() {
      if (!this.$store.state.contacts_report.report_id) {
        return false;
      }
      if (!this.$store.state.authed_xero.current_report_id) {
        return false;
      }
      return (
        this.$store.state.authed_xero.current_report_id !=
        this.$store.state.contacts_report.report_id
      );
    },
    clientDashUrl() {
      const params = (new URL(document.location)).searchParams;
      const clientDash = params.get('clientdash');
      if (clientDash) {
        return 'https://evenly.com.au/paypredict/' + clientDash
      }
      return null
    },
  }
};
</script>

<style scoped lang="scss">
$background: #fff;
$color: #333;
$focus-background: #eee;
$focus-color: #4d82ad;


#shareLinkText {
  word-break: break-all;
  background: #f2fcff;
  border: thin solid #e3e3e3;
  border-radius: 4px;
}
#shareLinkText input {
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 8px;
}


// login form

.form-inline {
  margin: -5px 0 0 0;
  input,
  button {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;

    height: 40px;
    font-size: 14px;
  }
  input {
    width: 150px;
    background-color: $background;
    border: 1px solid $focus-color;
  }
  button {
    background-color: none;
    border: 1px solid $background;
    color: $background !important;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    transform: translate(0px, 0px);
    &:hover {
      transform: translate(0px, -5px);
    }
    &:active {
      transform: translate(0px, -2px);
    }
  }
}

.page_navigation {
  z-index: 1200;
  position: relative;
}
.account_select {
  z-index: 1000;
  position: relative;
}
ul.nav {
  background: $background;
  list-style: none;
  margin: 0;
  padding-left: 0;

  li {
    color: $color;
    background: $background;
    display: block;
    float: right;
    // padding: 5px 10px;
    // margin-right: 10px;
    position: relative;
    text-decoration: none;
    transition-duration: 0.5s;
    border-radius: 3px;
    &.thin {
      padding: 5px 0px;
      hr {
        margin: 0;
      }
      &:hover,
      &:focus-within {
        background: $background;
        cursor: default;
      }
    }
    a {
      color: $color;
      display: block;
      width: 100%;
      &:hover {
        color: $focus-color;
      }
    }
    &:hover,
    &:focus-within {
      background: $focus-background;
      cursor: pointer;
    }
    &:focus-within a {
      outline: none;
    }
    ul {
      text-align: left;
      min-width: 200px;
      background: $background;
      visibility: hidden;
      opacity: 0;
      min-width: 8rem;
      position: absolute;
      transition: all 0.5s ease;
      margin-top: 2px;
      padding-top: 5px;
      right: 0;
      display: none;
      border-radius: 3px;
      -webkit-box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
      li {
        clear: both;
        width: 100%;
        min-width: 200px;
      }
    }
    &:hover > ul,
    &:focus-within > ul,
    ul:hover,
    ul:focus {
      visibility: visible;
      opacity: 1;
      display: block;
    }
  }
}

// header

.loading a {
  text-decoration: underline;
}
</style>
