import Vue from 'vue'
import Router from 'vue-router'

/* Implemented as a factory in order to prevent the router from
 * auto loading
 */
function router_factory() {

    Vue.use(Router)

    return new Router({
        routes: [
            {
                path: '/',
                name: 'push',
                component: () => import('./views/RedirectHandler.vue'),
                meta: {
                    title: "Evenly PayPredict"
                }
            },
            {
                path: '/dashboard/',
                name: 'home',
                component: () => import('./views/Dashboard.vue'),
                meta: {
                    title: "Dashboard"
                }
            },
            {
                path: '/profile/:id',
                name: 'profile',
                component: () => import('./views/CustomerProfile.vue'),
                meta: {
                    title: "Customer Detail"
                }
            },
        ],
        scrollBehavior() {
            return { x: 0, y: 0 }
        }
    })
}

export default router_factory
